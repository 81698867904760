$icon-font-path: '../fonts/';

// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
// @import 'default';
// @import 'component';
// @import 'bootstrap';
// @import 'modern-business';
@import 'node_modules/font-awesome/scss/font-awesome';

@import "http://fonts.googleapis.com/css?family=Montserrat:400,700";
@import "http://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic";

#locations {
    .panel-default {
        border: none !important;
        background: none;
        .panel-heading {
            background: none;
            .panel-title {
                color: #fff;
            }
        }
        .panel-body {
            border: none;
            padding: 0 15px;
        }
    }
}
